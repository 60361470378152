import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { createRequest } from "../createRequest";

export const useApiMutation = <TResponse, TParams = undefined>(
  endpoint: string,
  method: "POST" | "PUT" | "DELETE" = "POST",
  options?: UseMutationOptions<TResponse, any, TParams, unknown>
) =>
  useMutation<TResponse, any, TParams>({
    mutationFn: (data: TParams) =>
      createRequest<TResponse, TParams>({
        endpoint,
        method,
        data,
      }),
    ...options,
  });
