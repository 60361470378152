import React, {useState} from 'react';
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    TextField,
    Alert,
    AlertTitle,
    LinearProgress,
} from '@mui/material';
import {Check, Close} from '@mui/icons-material';
import {VerificationResult, verifyDefinitionWS} from "../../api/verifyDefinitionWS";
import {useTranslation} from "react-i18next";
import {WebSocketMessage} from "../../api/websocketTypes";

const DefinitionVerification: React.FC = () => {
    const {t} = useTranslation();

    const [input, setInput] = useState({word: '', claim: ''});
    const [verificationResult, setVerificationResult] = useState<VerificationResult | null>(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [progress, setProgress] = useState<string | null>(null);

    const handleVerify = () => {
        setIsVerifying(true);
        setProgress(null);
        setVerificationResult(null);
        setError(null);

        const ws = verifyDefinitionWS(input, (message: WebSocketMessage<VerificationResult>) => {
            switch (message.type) {
                case 'progress':
                    setProgress(message.message);
                    break;
                case 'result':
                    setVerificationResult(message.data);
                    setIsVerifying(false);
                    ws.close();
                    break;
                case 'error':
                    setError(message.message);
                    setIsVerifying(false);
                    ws.close();
                    break;
            }
        });
    };

    const renderResult = () => {
        if (isVerifying) {
            return (
                <Alert severity="info" icon={<CircularProgress size={20}/>}>
                    <AlertTitle>{t('pages.definitionVerification.verifying')}</AlertTitle>
                    {t(`pages.definitionVerification.${progress}`) || t('pages.definitionVerification.pleaseWait')}
                    <LinearProgress variant="indeterminate" sx={{mt: 2}}/>
                </Alert>
            );
        }

        if (error) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            );
        }

        if (verificationResult) {
            return (
                <Alert severity={verificationResult.predicted === 'SUPPORTED' ? 'success' : 'error'}
                       icon={verificationResult.predicted === 'SUPPORTED' ? <Check/> : <Close/>}>
                    <AlertTitle>
                        {verificationResult.predicted === 'SUPPORTED' ? t('pages.definitionVerification.supported') :
                            t('pages.definitionVerification.notSupported')}
                    </AlertTitle>
                    {verificationResult.in_wiki === 'Yes' ? (
                        <>
                            {t('pages.definitionVerification.evidenceFound')}
                            {verificationResult.selected_evidences && verificationResult.selected_evidences.length > 0 && (
                                <Box component="details" sx={{mt: 2}}>
                                    <summary style={{
                                        cursor: 'pointer',
                                        fontWeight: 'medium'
                                    }}>{t('pages.definitionVerification.viewEvidence')}
                                    </summary>
                                    <ul style={{marginTop: '8px', paddingLeft: '20px'}}>
                                        {verificationResult.selected_evidences.map((evidence, index) => (
                                            <li key={index} style={{marginTop: '4px'}}>
                                                <span
                                                    style={{fontWeight: 'medium'}}>{evidence.title}:</span> {evidence.text}
                                                {evidence.in_intro && <span style={{
                                                    marginLeft: '8px',
                                                    fontSize: '0.875rem',
                                                    color: 'text.secondary'
                                                }}>(Intro)</span>}
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </>
                    ) : (
                        t('pages.definitionVerification.noEvidence')
                    )}
                </Alert>
            );
        }

        return null;
    };

    return (
        <CardContent>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <TextField
                    label={t('pages.definitionVerification.word')}
                    value={input.word}
                    onChange={(e) => setInput(prev => ({
                        ...prev,
                        word: e.target.value
                    }))}
                    placeholder={t('pages.definitionVerification.enterWord')}
                />
                <TextField
                    label={t('pages.definitionVerification.definition')}
                    value={input.claim}
                    onChange={(e) => setInput(prev => ({
                        ...prev,
                        claim: e.target.value
                    }))}
                    placeholder={t('pages.definitionVerification.enterDefinition')}
                />
            </Box>
            <Box sx={{mt: 2, display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    onClick={handleVerify}
                    disabled={isVerifying || !input.word || !input.claim}
                >
                    {t('pages.definitionVerification.verifyDefinition')}
                </Button>
            </Box>
            <Box sx={{mt: 2}}>
                {renderResult()}
            </Box>
        </CardContent>
    );
}

export default DefinitionVerification;
