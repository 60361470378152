import React, {useEffect} from 'react';
import {I18nextProvider} from 'react-i18next';
import i18n from './consts/i18n';
import {ThemeProvider} from '@mui/material/styles';
import theme from './styles/theme';
import {BrowserRouter, useLocation, useRoutes} from 'react-router-dom';
import {CssBaseline} from "@mui/material";
import routes from "./consts/routes";
import {HelmetProvider} from "react-helmet-async";
import {initGA, logPageView} from "./analytics";

const AppRoutes: React.FC = () => {
  return useRoutes(routes);
};

const TrackPageView: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return null;
};

const App: React.FC = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <TrackPageView />
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </I18nextProvider>
    </HelmetProvider>
  );
};

export default App;
