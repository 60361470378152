import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import HeroSection from "../components/heroSection";
import ToolCard from '../components/toolCard';
import Grid from '@mui/material/Grid2';
import {tools} from "../consts/tools";
import {useTranslation} from "react-i18next";
import SeoComponent from "../components/seoComponent";

const HomePage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <SeoComponent/>
      <HeroSection />
      <Container sx={{ marginTop: 6 }}>
        <Box>
          <Typography variant="h4" sx={{ marginBottom: 4, fontWeight: 'bold' }}>
            {t("pages.home.popularTools")}
          </Typography>
          <Grid container spacing={4} id="tools">
            {tools.map((tool, index) => (
              <Grid size={{xs: 12, sm: 6, md: 3}} key={index}>
                <ToolCard {...tool} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
