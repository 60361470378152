import React from "react";
import {useData} from "../hooks/useData";

const ApiTest = () => {
  const { example } = useData();

  const { mutate, error, isPending, data } = example;

  const handleOptimize = () => {
    mutate(
      {
        "name": "asdf",
        "description": "das ist ein test"
      }
    );
  };

  return (
    <div>
      <button onClick={handleOptimize} disabled={isPending}>
        {isPending ? "Optimizing..." : "Optimize Content"}
      </button>

      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
      {data && <p>Optimized Content: {data.description}</p>}
    </div>
  );
};

export default ApiTest;
