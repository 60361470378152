  export const tools = [
    // { title: 'PDF Merge', description: 'Combine multiple PDFs into one file.', link: '/pdf-merge' },
    // { title: 'PDF Edit', description: 'Edit text and images in your PDFs.', link: '/pdf-edit' },
    { i18PageName: 'json', link: '/json-editor' },
    { i18PageName: 'randomGenerator', link: '/random-generator' },
    { i18PageName: 'factSplitting', link: '/fact-split' },
    { i18PageName: 'summarizer', link: '/text-summarizer' },
    { i18PageName: 'definitionVerification', link: '/verify-definition' },
    { i18PageName: 'claimVerification', link: '/verify-claim' },
    { i18PageName: 'definitionGeneration', link: '/get-definition' },
  ];