import React, {useState, useMemo, useCallback} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Alert,
    Stack,
    LinearProgress,
    AlertTitle,
} from "@mui/material";
import {
    BaseEditor,
    createEditor,
    Descendant,
    Text,
    Node,
    Editor,
    Range,
    Transforms,
    Path,
} from "slate";
import {ReactEditor, Slate, Editable, withReact, RenderLeafProps} from "slate-react";
import {useTranslation} from "react-i18next";
import {WebSocketMessage} from "../../api/websocketTypes";
import {generateDefinitionWS, GenerationResult} from "../../api/generateDefinitionWS";
import {getEnglishNameByLocale} from "../../consts/languages";
import {getNormalizedLanguage} from "../../consts/i18n";

type CustomEditor = BaseEditor & ReactEditor;

declare module "slate" {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: { type: string; children: CustomText[] };
        Text: CustomText;
    }
}

type CustomText = { text: string; highlighted?: boolean };
type InitialValue = Descendant[];

const initialValue: InitialValue = [
    {
        type: "paragraph",
        children: [{text: ""}],
    },
];

const DefinitionGeneration: React.FC = () => {
    const {t} = useTranslation();
    const editor = useMemo(() => withReact(createEditor()), []);
    const [value, setValue] = useState<InitialValue>(initialValue);
    const [highlightedWord, setHighlightedWord] = useState<{
        word: string;
        path: number[];
        start: number;
        end: number;
    } | null>(null);
    const [generationResult, setGenerationResult] = useState<GenerationResult | null>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [progress, setProgress] = useState<string | null>(null);

    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    const decorate = useCallback(
        ([node, path]: [Node, number[]]) => {
            const ranges: Array<Range & { highlighted?: boolean }> = [];
            if (Text.isText(node)) {
                const text = node.text || "";
                const words = text.split(" ");
                let offset = 0;

                words.forEach((word) => {
                    const start = offset;
                    const end = start + word.length;

                    const isHighlighted =
                        highlightedWord &&
                        highlightedWord.word === word &&
                        Path.equals(path, highlightedWord.path) &&
                        start === highlightedWord.start;

                    ranges.push({
                        anchor: {path, offset: start},
                        focus: {path, offset: end},
                        highlighted: isHighlighted ? true : undefined,
                    });

                    offset += word.length + 1;
                });
            }
            return ranges;
        },
        [highlightedWord]
    );

    const handleWordClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const {selection} = editor;

            if (Node.string(editor) === "") {
                Transforms.insertText(editor, "");
                return;
            }

            if (selection) {
                const {anchor} = selection;
                const [node] = Editor.node(editor, anchor.path);

                if (Text.isText(node)) {
                    const text = Node.string(node) || " ";
                    const words = text.split(" ").filter((word) => word.trim() !== "");
                    let offset = 0;

                    for (const word of words) {
                        const wordStart = offset;
                        const wordEnd = wordStart + word.length;

                        if (anchor.offset >= wordStart && anchor.offset <= wordEnd) {
                            setHighlightedWord({
                                word,
                                path: anchor.path,
                                start: wordStart,
                                end: wordEnd,
                            });
                            setGenerationResult(null);
                            setError(null);
                            return;
                        }

                        offset += word.length + 1;
                    }
                }
            }

            setHighlightedWord(null);
        },
        [editor]
    );

    const handleGeneration = () => {
        if (!highlightedWord) return;

        const contextSentence = Node.string(editor);

        setIsGenerating(true);
        setError(null);
        setProgress("starting");

        const ws = generateDefinitionWS(
            {
                word: highlightedWord.word,
                context_sentence: contextSentence,
                language: getEnglishNameByLocale(getNormalizedLanguage())
            },
            (message: WebSocketMessage<GenerationResult>) => {
                switch (message.type) {
                    case "progress":
                        setProgress(message.message);
                        break;
                    case "result":
                        setGenerationResult(message.data);
                        setIsGenerating(false);
                        setProgress(null);
                        ws.close();
                        break;
                    case "error":
                        setError(message.message);
                        setIsGenerating(false);
                        setProgress(null);
                        ws.close();
                        break;
                }
            }
        );
    };

    return (
        <Card sx={{maxWidth: 600, width: "100%", borderRadius: 4, boxShadow: 3}}>
            <CardContent>
                <Stack spacing={2} mt={2}>
                    <Box
                        sx={{
                            p: 2,
                            backgroundColor: "#ffffff",
                            border: "1px solid #e0e0e0",
                            borderRadius: "12px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            minHeight: 50,
                            transition: "all 0.3s ease-in-out",
                            "&:focus-within": {
                                border: "1px solid #64b5f6",
                                boxShadow: "0px 6px 12px rgba(100, 181, 246, 0.2)",
                            },
                        }}
                    >
                        <Slate
                            editor={editor}
                            initialValue={value}
                            onChange={(newValue) => {
                                setValue(newValue);

                                // Check if the highlighted word still exists in the content
                                if (highlightedWord) {
                                    const currentText = Node.string(editor); // Get the current text in the editor
                                    if (!currentText.includes(highlightedWord.word)) {
                                        setHighlightedWord(null); // Reset if the word is no longer present
                                    }
                                }
                            }}
                        >
                            <Editable
                                renderLeaf={renderLeaf}
                                decorate={decorate}
                                onClick={handleWordClick}
                                placeholder={t('pages.definitionGeneration.placeHolder')}
                                role="textbox"
                                aria-multiline="true"
                                style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.6",
                                    color: "#424242",
                                    outline: "none",
                                    padding: "4px 8px",
                                    minHeight: "50px",
                                }}
                            />
                        </Slate>
                    </Box>

                    {highlightedWord && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGeneration}
                            disabled={isGenerating}
                            startIcon={isGenerating ? <CircularProgress size={20}/> : null}
                            fullWidth
                        >
                            {t("pages.definitionGeneration.getDefinition")} - {highlightedWord.word}
                        </Button>
                    )}

                    {isGenerating && (
                        <Alert severity="info" icon={<CircularProgress size={20}/>}>
                            <AlertTitle>{t("pages.definitionGeneration.generating")}</AlertTitle>
                            {t(`pages.definitionGeneration.${progress}`) || t("pages.definitionGeneration.pleaseWait")}
                            <LinearProgress variant="indeterminate" sx={{mt: 2}}/>
                        </Alert>
                    )}

                    {error && (
                        <Alert severity="error">
                            <Typography>{error}</Typography>
                        </Alert>
                    )}

                    {generationResult && (
                        <Box>
                            <Typography variant="body1">
                                <strong>{t("pages.definitionGeneration.word")}:</strong> {generationResult.word}
                            </Typography>
                            <Typography variant="body1">
                                <strong>{t("pages.definitionGeneration.definition")}:</strong> {generationResult.definition}
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

const Leaf: React.FC<RenderLeafProps> = ({attributes, children, leaf}) => {
    return (
        <span
            {...attributes}
            style={{
                display: "inline-block",
                padding: (leaf as CustomText).highlighted ? "4px 8px" : "0",
                margin: (leaf as CustomText).highlighted ? "0 2px" : "0",
                borderRadius: (leaf as CustomText).highlighted ? "8px" : "0",
                background: (leaf as CustomText).highlighted
                    ? "linear-gradient(135deg, #FFEA7A, #FFC107)"
                    : "transparent",
                color: (leaf as CustomText).highlighted ? "#212121" : "inherit",
                fontWeight: (leaf as CustomText).highlighted ? "bold" : "normal",
                boxShadow: (leaf as CustomText).highlighted
                    ? "0px 4px 12px rgba(255, 193, 7, 0.4)"
                    : "none",
                transition: "all 0.3s ease-in-out",
                transform: (leaf as CustomText).highlighted ? "scale(1.1)" : "none",
                cursor: "pointer",
            }}
        >
      {children}
    </span>
    );
};

export default DefinitionGeneration;
