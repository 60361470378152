import {Navigate, useLocation} from "react-router-dom";
import {getNormalizedLanguage} from "../consts/i18n";
import {isLocaleIncluded} from "../consts/languages";

interface LanguageValidatorProps {
  children: React.ReactNode;
}

const LanguageValidator: React.FC<LanguageValidatorProps> = ({ children }) => {
  const location = useLocation();
  const detectedLanguage = getNormalizedLanguage();

  const pathMatch = location.pathname.match(/^\/([^/]+)(\/.*)?$/); // Matches the first segment and the rest of the path
  const lng = pathMatch ? pathMatch[1] : null;
  const restOfPath = pathMatch && pathMatch[2] ? pathMatch[2] : "";

  if (!lng || !isLocaleIncluded(lng)) {
    // If no valid language or invalid language, redirect with the detected language
    const newPath = restOfPath
      ? `/${detectedLanguage}${restOfPath}`
      : `/${detectedLanguage}${location.pathname}`;
    return <Navigate to={newPath} replace />;
  }

  return <>{children}</>;
};

export default LanguageValidator;