import React, {useState} from 'react';
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    TextField,
    Alert,
    AlertTitle,
    LinearProgress,
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {VerificationResult, verifyStatementWS} from "../../api/verifyStatementWS";
import VerificationResultComponent from "./verificationResultComponent";
import {WebSocketMessage} from "../../api/websocketTypes";

const ClaimVerification: React.FC = () => {
    const {t} = useTranslation();

    const [input, setInput] = useState({claim: ''});
    const [verificationResult, setVerificationResult] = useState<VerificationResult | null>(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [progress, setProgress] = useState<string | null>(null);

    const handleVerify = () => {
        setIsVerifying(true);
        setProgress(null);
        setVerificationResult(null);
        setError(null);

        const ws = verifyStatementWS(input, (message: WebSocketMessage<VerificationResult>) => {
            switch (message.type) {
                case 'progress':
                    setProgress(message.message);
                    break;
                case 'result':
                    setVerificationResult(message.data);
                    setIsVerifying(false);
                    ws.close();
                    break;
                case 'error':
                    setError(message.message);
                    setIsVerifying(false);
                    ws.close();
                    break;
            }
        });
    };

    const renderResult = () => {
        if (isVerifying) {
            return (
                <Alert severity="info" icon={<CircularProgress size={20}/>}>
                    <AlertTitle>{t('pages.claimVerification.verifying')}</AlertTitle>
                    {t(`pages.claimVerification.${progress}`) || t('pages.claimVerification.pleaseWait')}
                    <LinearProgress variant="indeterminate" sx={{mt: 2}}/>
                </Alert>
            );
        }

        if (error) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            );
        }

        if (verificationResult) {
            return <VerificationResultComponent verificationResult={verificationResult} t={t}/>
        }

        return null;
    };

    return (
        <CardContent>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <TextField
                    label={t('pages.claimVerification.claim')}
                    value={input.claim}
                    onChange={(e) => setInput({'claim': e.target.value})}
                    placeholder={t('pages.claimVerification.enterClaim')}
                />
            </Box>
            <Box sx={{mt: 2, display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    onClick={handleVerify}
                    disabled={isVerifying || !input.claim}
                >
                    {t('pages.claimVerification.verifyClaim')}
                </Button>
            </Box>
            <Box sx={{mt: 2}}>
                {renderResult()}
            </Box>
        </CardContent>
    );
}

export default ClaimVerification;
