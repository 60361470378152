import React, { useState, useEffect } from 'react';
import { Button, IconButton, Tooltip, Box, Snackbar, Alert, TextField } from "@mui/material";
import { ContentCopy as CopyIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useData } from "../../hooks/useData";
import { getNormalizedLanguage } from "../../consts/i18n";
import { getEnglishNameByLocale } from "../../consts/languages";

const TextSummarizer: React.FC = () => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState<string>('');
  const [summaryText, setSummaryText] = useState<string>('');
  const [showSummarized, setShowSummarized] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const { summarizer } = useData();
  const { mutate, data, error, isPending } = summarizer;

  useEffect(() => {
    if (data) {
      setSummaryText(data.summary || '');
      setShowSummarized(true);
      setIsModified(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setSummaryText(t("pages.summarizer.error"));
      setShowSummarized(true);
    }
  }, [error, t]);

  useEffect(() => {
    setIsModified(true);
  }, [inputText]);

  const handleSummarize = () => {
    if (isModified) {
      mutate({
        text: inputText,
        language: getEnglishNameByLocale(getNormalizedLanguage())
      });
    } else {
      setShowSummarized(true);
    }
  };

  const handleRawView = () => {
    setShowSummarized(false);
  };

  const handleFormattedView = () => {
    if (!showSummarized) {
      handleSummarize();
    } else {
      setShowSummarized(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(showSummarized ? summaryText : inputText).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
      <TextField
        multiline
        fullWidth
        minRows={5}
        maxRows={10}
        value={showSummarized ? summaryText : inputText}
        onChange={(e) =>
          showSummarized
            ? setSummaryText(e.target.value)
            : setInputText(e.target.value)
        }
        placeholder={t("pages.summarizer.enterText")}
        variant="outlined"
        slotProps={{
          input: {
            style: {
              fontFamily: "monospace",
              fontSize: "14px",
            },
          },
          htmlInput: {
            readOnly: showSummarized, // Set the field as read-only using slotProps.htmlInput
          },
        }}
        sx={{
          width: '100%',
          maxWidth: '600px',
        }}
      />
      <Box display="flex" gap={2}>
        {!showSummarized ? (
          <Button
            onClick={handleFormattedView}
            variant="contained"
            color="primary"
            disabled={isPending}
          >
            {isPending
              ? t("pages.summarizer.loading")
              : t("pages.summarizer.summarize")}
          </Button>
        ) : (
          <Button
            onClick={handleRawView}
            variant="outlined"
            color="secondary"
          >
            {t("pages.summarizer.originalText")}
          </Button>
        )}
        <Tooltip title={t("general.copyToClipboard")}>
          <IconButton onClick={handleCopy} color="default">
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {t("general.copiedToClipboard")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TextSummarizer;
