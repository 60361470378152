import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import {useMetaData} from "../hooks/useMetaData";
import {languages} from "../consts/languages";
import LanguageValidator from "../components/languageValidator";
import {domain} from "../consts/domain";

const LanguageHelmet: React.FC = () => {
    const location = useLocation();
    const page = location.pathname.split("/")[2];
    const metaData = useMetaData(page);
    const urlSplit = metaData.canonicalUrl.split(domain)
    const path = urlSplit[1] || ''

    return (
      <>
        <Helmet>
          {languages.map(lang => (
            <link
              key={lang.locale}
              rel="alternate"
              hrefLang={lang.locale}
              href={`https://tools.ellngr.com/${lang.locale}/${path}`}
            />
          ))}
        </Helmet>
      </>
    );
};

const LanguageLayout: React.FC = () => {
  return (
    <LanguageValidator>
      <LanguageHelmet/>
      <Outlet/>
    </LanguageValidator>
  )
}

export default LanguageLayout;