import { useTranslation } from "react-i18next";

interface StructuredData {
  "@context": string;
  "@type": string; // Either "WebPage" or "SoftwareApplication"
  name: string;
  description: string;
  url: string;
  applicationCategory?: string; // Optional, only for "SoftwareApplication"
  operatingSystem?: string; // Optional, only for "SoftwareApplication"
  breadcrumb?: {
    "@type": string;
    itemListElement: {
      "@type": string;
      position: number;
      name: string;
      item: string;
    }[];
  };
  isPartOf?: {
    "@type": string;
    name: string;
    url: string;
  }; // Optional, only for "WebPage"
  creator: {
    "@type": string;
    name: string;
  };
}


export const useStructuredData = (page: string): StructuredData | null => {
  const { t } = useTranslation();

  const isTool = page !== 'home'

  const breadcrumbList = {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: t("general.home"),
        item: "https://tools.ellngr.com",
      },
      ...(isTool
        ? [
            {
              "@type": "ListItem",
              position: 2,
              name: t(`seo.${page}.struct.name`),
              item: t(`seo.${page}.meta.canonicalUrl`),
            },
          ]
        : []),
    ],
  };

  return {
    "@context": "https://schema.org",
    "@type": isTool ? "SoftwareApplication" : "WebPage",
    name: t(`seo.${page}.struct.name`),
    description: t(`seo.${page}.struct.description`),
    url: t(`seo.${page}.meta.canonicalUrl`),
    ...(isTool
      ? {
          applicationCategory: "Utility",
          operatingSystem: "All",
          breadcrumb: breadcrumbList,
        }
      : {
          isPartOf: {
            "@type": "WebSite",
            name: t(`general.appName`),
            url: "https://tools.ellngr.com",
          },
          breadcrumb: breadcrumbList,
        }),
    creator: {
      "@type": "Person",
      name: "Lukas Ellinger",
    },
  };
};

