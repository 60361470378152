import axios from "axios";

const apiUrl = "https://tools.ellngr.com/api";

export const createRequest = async <TResponse, TParams = undefined>({
  endpoint,
  method = "GET",
  data,
  signal,
}: {
  endpoint: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  data?: TParams;
  signal?: AbortSignal;
}): Promise<TResponse> => {
  const url = `${apiUrl}/${endpoint}`;

  try {
    const response = await axios({
      url,
      method,
      data,
      headers: { "Content-Type": "application/json" },
      signal,
    });
    return response.data as TResponse;
  } catch (error) {
    throw error; // Allow errors to be handled at the hook or component level
  }
};
