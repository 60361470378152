import React from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useTranslation } from "react-i18next";

const scaleUp = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

interface ToolProps {
  i18PageName: string;
  link: string;
}

const ToolCard: React.FC<ToolProps> = ({ i18PageName, link }) => {
  const { t } = useTranslation();

  return (
    <Card
      component="a"
      href={link}
      sx={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 2,
        boxShadow: 3,
        height: '100%',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          animation: `${scaleUp} 0.3s ease-out forwards`,
          boxShadow: 6,
        },
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5">{t(`pages.${i18PageName}.title`)}</Typography>
        <Typography variant="body2" color="text.secondary">
          {t(`pages.${i18PageName}.homeDescription`)}
        </Typography>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <Button size="small" color="primary" variant="contained">
          {t("components.toolCard.useTool")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ToolCard;
