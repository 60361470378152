import { Box, Typography, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

const HeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
        textAlign: 'center',
        padding: 3,
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {t("components.heroSection.title")}
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {t("components.heroSection.heroStatement")}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          href="#tools"
          sx={{ padding: '10px 30px', fontSize: '16px' }}
        >
          {t("components.heroSection.getStarted")}
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;
