import React, { useState } from 'react';
import Flag from 'react-world-flags';
import { Button, Popover, MenuItem, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import i18next from "i18next";
import {languages} from "../consts/languages";
import {useLocation, useNavigate} from "react-router-dom";

const LanguageSwitcher: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (locale: string) => {
    i18next.changeLanguage(locale);
    const currentPath = location.pathname.replace(/^\/[^/]+/, '');
    navigate(`/${locale}${currentPath}`);
    handlePopoverClose();
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <div
      style={{ display: 'inline-block' }}
    >
      <Button
        startIcon={<LanguageIcon/>}
        onClick={handlePopoverOpen}
        sx={{
          color: 'inherit',
        }}
      >
      </Button>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus // Prevents unexpected focus when hovering
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.locale}
            onClick={() => handleLanguageChange(lang.locale)}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <Flag code={lang.code} style={{ width: '20px' }} />
            <Typography>{lang.name}</Typography>
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
};

export default LanguageSwitcher;
