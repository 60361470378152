export const languages = [
  { code: 'US', name: 'English', english_name: 'English', locale: 'en' }, // English
  { code: 'DE', name: 'Deutsch', english_name: 'German', locale: 'de' }, // German
  //{ code: 'ES', name: 'Español', locale: 'es' }, // Spanish
  //{ code: 'FR', name: 'Français', locale: 'fr' }, // French
  //{ code: 'PT', name: 'Português', locale: 'pt' }, // Portuguese
  //{ code: 'IT', name: 'Italiano', locale: 'it' }, // Italian
];

export const getEnglishNameByLocale = (locale: string): string | undefined => {
  const entry = languages.find(language => language.locale === locale);
  return entry ? entry.english_name : undefined;
};

export const isLocaleIncluded = (localeToCheck: string) => {
  return languages.some(language => language.locale === localeToCheck);
};