import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import {useTranslation} from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        padding: 4,
        textAlign: 'center',
        marginTop: 6,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        © 2024 {t("general.appName")}. {t("general.allRightsReserved")}.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <Link href="/privacy-policy" color="inherit">
          {t("general.privacyPolicy")}
        </Link>{' '}
        |{' '}
        <Link href="/terms" color="inherit">
          {t("general.termsOfService")}
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
