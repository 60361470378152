import { useApiMutation } from "./useApiMutation";
import { API_ENDPOINTS } from "../endpoints";

export const useFactsplit = () =>
  useApiMutation<FactsplitResponse, FactsplitParams>(
    API_ENDPOINTS.FACTSPLIT,
    "POST"
  );

interface FactsplitParams {
  sentence: string;
  language?: string;
}

interface FactsplitResponse {
  original_sentence: string;
  facts: string[];
}
