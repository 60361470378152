import React from 'react';
import { Box, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";

const Contact: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={4}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("pages.contact.title")}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        {t("pages.contact.name")}: Lukas Ellinger
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        {t("pages.contact.email")}: <a href="mailto:youremail@example.com">lukas@ellngr.com</a>
      </Typography>
    </Box>
  );
};

export default Contact;
