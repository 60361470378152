import { useApiMutation } from "./useApiMutation";
import { API_ENDPOINTS } from "../endpoints";

export const useJsonFix = () =>
  useApiMutation<JsonFixResponse, JsonFixParams>(
    API_ENDPOINTS.JSONFIX,
    "POST"
  );

interface JsonFixParams {
  malformed_json: string;
  language?: string;
}

interface JsonFixResponse {
  json: Record<string, any>;
  explanation: string;
}
