import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007BFF',
    },
    secondary: {
      main: '#28A745',
    },
  },
  components: {
    // Adding global styles to the theme
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: 'smooth',
        },
        body: {
          margin: 0,
          padding: 0,
        },
        '[id]': {
          scrollMarginTop: '100px', // Adjust this value based on your fixed header height
        },
      },
    }
  }
});


export default theme;
