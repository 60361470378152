import { useApiMutation } from "./useApiMutation";
import { API_ENDPOINTS } from "../endpoints";

export const useSummarizer = () =>
  useApiMutation<SummarizerResponse, SummarizerParams>(
    API_ENDPOINTS.SUMMARIZE,
    "POST"
  );

interface SummarizerParams {
  text: string;
  language?: string;
}

interface SummarizerResponse {
  summary: string;
}
