import React, {lazy, Suspense} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import {useMetaData} from "../hooks/useMetaData";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import SeoComponent from "../components/seoComponent";
import {getNormalizedLanguage} from "../consts/i18n";

const ToolLayout: React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const page = location.pathname.split("/")[2];
  const metaData = useMetaData(page);
  const i18PageName = metaData.i18PageName;

  const NoneComponent = () => null;
  const AboutComponent = lazy(async () => {
    try {
      const module = await import(`../consts/locales/${getNormalizedLanguage()}/aboutSections`);
      const Component = module[t(`pages.${i18PageName}.about`)];
      return { default: Component || NoneComponent };
    } catch {
      return { default: NoneComponent };
    }
  });

  return (
    <>
      <SeoComponent/>
      <Header withPopover={true}/>
      <Box display="flex" flexDirection="column" alignItems="center" gap={4} p={4}>
        <Typography variant="h4" align="center" gutterBottom>
          {t(`pages.${i18PageName}.heading`)}
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
          {t(`pages.${i18PageName}.description`)}
        </Typography>

        <Box mt={4} width="100%" maxWidth="600px" marginBottom={10}>
          <Outlet/>
        </Box>
        <Suspense>
          <AboutComponent/>
        </Suspense>
      </Box>
      <Footer/>
    </>
  );
};

export default ToolLayout;
