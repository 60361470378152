import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Link,
  Popover,
  MenuItem
} from '@mui/material';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";
import {tools} from "../consts/tools";

const Header: React.FC<{ withPopover?: boolean }> = ({ withPopover = false }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'primary.main' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link href="/" underline="none" color="inherit">
            {t("general.appName")}
          </Link>
        </Typography>
        <Button color="inherit" href="/">
          {t("general.home")}
        </Button>
        {withPopover ? (
          <>
            <Button color="inherit" onClick={handlePopoverOpen}>
              {t("general.tools")}
            </Button>
            <Popover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            {tools.map((tool) => (
                <MenuItem
                  key={t(`pages.${tool.i18PageName}.title`)}
                  component={"a"}
                  href={tool.link}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Typography>{t(`pages.${tool.i18PageName}.title`)}</Typography>
                </MenuItem>
              ))}
            </Popover>
          </>
        ) : (
          <Button color="inherit" href="#tools">
            {t("general.tools")}
          </Button>
        )}
        <Button color="inherit" href="/contact">
          {t("general.contact")}
        </Button>
        <Box>
          <LanguageSwitcher />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
