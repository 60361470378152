import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
  Button,
  Chip,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import {Casino as RandomIcon, Delete as DeleteIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const RandomGenerator: React.FC = () => {
  const {t} = useTranslation();
  const [inputList, setInputList] = useState<string>('');
  const [items, setItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawWheel = useCallback(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const size = canvas.width;
    const center = size / 2;
    const numItems = items.length;
    const anglePerItem = (2 * Math.PI) / numItems;

    ctx.clearRect(0, 0, size, size);

    if (items.length === 0) {
      ctx.beginPath();
      ctx.arc(center, center, center - 10, 0, 2 * Math.PI, false);
      ctx.fillStyle = '#f5f5f5';
      ctx.fill();
      ctx.strokeStyle = '#ddd';
      ctx.lineWidth = 2;
      ctx.stroke();
      return;
    }

    items.forEach((item, index) => {
      const startAngle = index * anglePerItem;
      const endAngle = startAngle + anglePerItem;
      const color = generateColor(index, numItems)

      ctx.beginPath();
      ctx.moveTo(center, center);
      ctx.arc(center, center, center, startAngle, endAngle, false);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.strokeStyle = '#fff';
      ctx.lineWidth = 2;
      ctx.stroke();

      ctx.save();
      ctx.translate(center, center);
      ctx.rotate(startAngle + anglePerItem / 2);
      ctx.textAlign = 'right';
      ctx.fillStyle = '#000';
      ctx.font = '14px Arial';
      ctx.fillText(item, center - 10, 5);
      ctx.restore();
    });
  }, [items]);

  useEffect(() => {
    drawWheel();
  }, [drawWheel]);

  const handleInputChange = (value: string) => {
    setInputList(value);

    const newItems = value
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '');

    setItems(newItems);
  };

  const handleDeleteItem = (item: string) => {
    const updatedItems = items.filter((i) => i !== item);
    setItems(updatedItems);
    setInputList(updatedItems.join(', '));
  };

  const generateColor = (index: number, total: number) => {
    return `hsl(${(index * 360) / total}, 70%, 80%)`;
  };

  const handleSpinWheel = () => {
    if (items.length === 0 || isSpinning) return;

    const randomIndex = Math.floor(Math.random() * items.length);
    const numItems = items.length;
    const anglePerItem = (2 * Math.PI) / numItems;

    // Ensure the randomOffset stays strictly within the selected field
    const randomOffset = (Math.random() - 0.5) * (anglePerItem * 0.95);

    // Calculate the target angle for the selected field
    const targetAngle =
      1.5 * Math.PI - (randomIndex + 0.5) * anglePerItem + randomOffset;

    const totalRotations = 5; // Number of full rotations
    const randomAngle = 2 * Math.PI * totalRotations + targetAngle;

    const duration = 3000; // Duration of the spin in milliseconds

    setIsSpinning(true);
    setSelectedItem(null);

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const start = performance.now();

    const animate = (time: number) => {
      const elapsed = time - start;
      const progress = Math.min(elapsed / duration, 1); // Clamp progress to 1
      const easeOut = 1 - Math.pow(1 - progress, 3); // Ease-out effect

      const angle = randomAngle * easeOut;

      // Clear and redraw the wheel
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(angle);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);
      drawWheel();
      ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformations

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Spin is complete
        setIsSpinning(false);
        setSelectedItem(items[randomIndex]); // Update the selected item
      }
    };

    requestAnimationFrame(animate);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={3} p={3}
         position="relative">
      <TextField
        value={inputList}
        onChange={(e) => handleInputChange(e.target.value)}
        placeholder={t("pages.randomGenerator.enterItems")}
        variant="outlined"
        multiline
        minRows={2}
        sx={{width: '100%'}}
      />
      <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        sx={{
          width: '100%',
          justifyContent: 'center',
          border: '1px solid #ddd',
          padding: 2,
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        {items.length > 0 ? (
          items.map((item, index) => (
            <Chip
              key={index}
              label={item}
              onDelete={() => handleDeleteItem(item)}
              deleteIcon={<DeleteIcon/>}
              sx={{
                backgroundColor: generateColor(index, items.length),
                color: '#000',
                fontWeight: 'bold',
              }}
              variant="outlined"
            />
          ))
        ) : (
          <Typography color="textSecondary">
            {t("pages.randomGenerator.noItems")}
          </Typography>
        )}
      </Box>
      <Box position="relative" width={300} height={300}>
        <canvas
          ref={canvasRef}
          width={300}
          height={300}
          style={{borderRadius: '50%', border: '2px solid #ddd'}}
        />
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '20px solid black',
            zIndex: 10,
          }}
        ></div>
      </Box>
      <Button
        onClick={handleSpinWheel}
        variant="contained"
        color="secondary"
        startIcon={<RandomIcon/>}
        disabled={items.length === 0 || isSpinning}
      >
        {t("pages.randomGenerator.selectRandom")}
      </Button>
      {selectedItem && (
        <Typography
          variant="h6"
          color="secondary"
          sx={{textAlign: 'center', marginTop: 2}}
        >
          {t("pages.randomGenerator.selectedItem")}: <strong>{selectedItem}</strong>
        </Typography>
      )}
    </Box>
  );
};

export default RandomGenerator;
