import {Alert, AlertTitle, Box, Button, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import {VerificationResult} from "../../api/verifyStatementWS";
import {useState} from "react";
import Grid from '@mui/material/Grid2';

const VerificationResultComponent = ({ verificationResult, t }: { verificationResult: VerificationResult, t: Function }) => {
  const [expandedAtoms, setExpandedAtoms] = useState<Record<number, boolean>>({});

  const toggleAtom = (index: number) => {
    setExpandedAtoms((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Alert
      severity={verificationResult.predicted === 'SUPPORTED' ? 'success' : 'error'}
      icon={verificationResult.predicted === 'SUPPORTED' ? <Check /> : <Close />}
    >
    <AlertTitle>
      {verificationResult.predicted === 'SUPPORTED'
        ? t('pages.claimVerification.supported')
        : t('pages.claimVerification.notSupported')}
      {verificationResult?.factuality !== undefined && (
        <>
          {' - '}{t('pages.claimVerification.factuality')}: {verificationResult.factuality.toFixed(2)}
        </>
      )}
    </AlertTitle>
      {verificationResult.in_wiki === 'Yes' ? (
        <>
          {t('pages.claimVerification.evidenceFound')}
          {verificationResult.atoms && verificationResult.atoms.length > 0 && (
            <Box component="details" sx={{ mt: 2 }}>
              <summary
                style={{
                  cursor: 'pointer',
                  fontWeight: 'medium',
                }}
              >
                {t('pages.claimVerification.viewFacts')}
              </summary>
              <ul style={{ marginTop: '8px', paddingLeft: '20px' }}>
                {verificationResult.atoms.map((atomData, atomIndex) => (
  <Box component="li" key={atomIndex} sx={{ mt: 2 }}>
    <Grid container spacing={2} alignItems="center">
      {/* Atom Label and Content */}
      <Grid size={{xs: 12}}>
        <Typography variant="body2">
          <strong>{t('pages.claimVerification.atom')} {atomIndex + 1}:</strong> {atomData.atom}
        </Typography>
      </Grid>

      {/* Prediction */}
      <Grid size={{xs: 6}}>
        <Typography variant="body2">
          {t('pages.claimVerification.prediction')}:{" "}
          {atomData.predicted === "SUPPORTED"
            ? t("pages.claimVerification.supported")
            : t("pages.claimVerification.notSupported")}
        </Typography>
      </Grid>

      {/* View Evidence Button */}
      <Grid size={{xs: 6}}>
        <Button
          size="small"
          onClick={() => toggleAtom(atomIndex)}
          variant="outlined"
        >
          {expandedAtoms[atomIndex]
            ? t("pages.claimVerification.hideEvidence")
            : t("pages.claimVerification.viewEvidence")}
        </Button>
      </Grid>
    </Grid>

    {/* Evidence List */}
    {expandedAtoms[atomIndex] && (
      <Box component="ul" sx={{ mt: 2, pl: 4 }}>
        {atomData.selected_evids.map((evidence, evidIndex) => (
          <Box component="li" key={evidIndex} sx={{ mt: 1 }}>
            <Typography variant="body2">
              <strong>{evidence.title}:</strong> {evidence.text}
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", mt: 0.5, color: "text.secondary" }}
            >
              <strong>{t("pages.claimVerification.similarity")}:{" "}</strong>
                {evidence.sim.toFixed(3)}
            </Typography>
          </Box>
        ))}
      </Box>
    )}
  </Box>
))}
              </ul>
            </Box>
          )}
        </>
      ) : (
        t('pages.claimVerification.noEvidence')
      )}
    </Alert>
  );
};

export default VerificationResultComponent;