import {WebSocketMessage} from "./websocketTypes";

export interface GenerationRequest {
  word: string;
  context_sentence: string;
  language?: string;
}

export interface GenerationResult {
  word: string;
  definition: string;
}

export function generateDefinitionWS(
  request: GenerationRequest,
  onMessage: (message: WebSocketMessage<GenerationResult>) => void
): WebSocket {
  const ws = new WebSocket('wss://tools.ellngr.com/api/generation/generate-definition/ws');

  ws.onopen = () => {
    ws.send(JSON.stringify(request));
  };

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data) as WebSocketMessage<GenerationResult>;
    onMessage(message);
  };

  ws.onerror = () => {
    onMessage({ type: 'error', message: 'WebSocket error occurred' });
  };

  return ws;
}