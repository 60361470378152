import { useApiMutation } from "./useApiMutation";
import { API_ENDPOINTS } from "../endpoints";

export const useExample = () =>
  useApiMutation<ExampleResponse, ExampleParams>(
    API_ENDPOINTS.EXAMPLE,
    "POST"
  );

interface ExampleParams {
  name: string;
  description: string;
}

interface ExampleResponse {
  name: string;
  description: string;
}
