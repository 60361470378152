import {WebSocketMessage} from "./websocketTypes";

export interface VerificationRequest {
  word: string;
  claim: string;
}

export interface VerificationResult {
  word: string;
  claim: string;
  predicted: string;
  in_wiki: string;
  selected_evidences?: Array<{
    title: string;
    text: string;
    line_idx: number;
    in_intro: boolean;
  }>;
}

export function verifyDefinitionWS(
  request: VerificationRequest,
  onMessage: (message: WebSocketMessage<VerificationResult>) => void
): WebSocket {
  const ws = new WebSocket('wss://tools.ellngr.com/api/verification/verify-definition/ws');

  ws.onopen = () => {
    ws.send(JSON.stringify(request));
  };

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data) as WebSocketMessage<VerificationResult>;
    onMessage(message);
  };

  ws.onerror = () => {
    onMessage({ type: 'error', message: 'WebSocket error occurred' });
  };

  return ws;
}