import {useTranslation} from "react-i18next";

interface MetaData {
  title: string;
  description: string;
  keywords: string;
  canonicalUrl: string;
  i18PageName: string;
}

export const useMetaData = (page: string): MetaData => {
  const { t } = useTranslation();

  return {
    title: t(`seo.${page}.meta.title`),
    description: t(`seo.${page}.meta.description`),
    keywords: t(`seo.${page}.meta.keywords`),
    canonicalUrl: t(`seo.${page}.meta.canonicalUrl`),
    i18PageName: t(`seo.${page}.meta.i18PageName`),
  };
};
