import {useExample} from "../api/hooks/useExample";
import {useSummarizer} from "../api/hooks/useSummarizer";
import {useFactsplit} from "../api/hooks/useFactsplit";
import {useJsonFix} from "../api/hooks/useJson";

export const useData = () => {
  // const useManufacturers = createQueryHook(API_ENDPOINTS.MANUFACTURERS);
  // const useMerchants = createQueryHook(API_ENDPOINTS.MERCHANTS);

  const example = useExample();
  const summarizer = useSummarizer();
  const factSplit = useFactsplit();
  const jsonFix = useJsonFix();

  return {
    // useManufacturers,
    // useMerchants,
    example,
    summarizer,
    factSplit,
    jsonFix
  };
};
