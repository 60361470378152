import React from 'react';
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import {useMetaData} from "../hooks/useMetaData";
import {useStructuredData} from "../hooks/useStructuredData";

const SeoComponent: React.FC = () => {
  const location = useLocation();
  const page = location.pathname.split("/")[2] || "home";
  const metaData = useMetaData(page);
  const structData = useStructuredData(page)

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="keywords" content={metaData.keywords} />
      <link rel="canonical" href={metaData.canonicalUrl} />
      {structData && (
        <script type="application/ld+json">
          {JSON.stringify(structData)}
        </script>
      )}
    </Helmet>
  );
};

export default SeoComponent;
