import React, {useEffect, useState} from 'react';
import {
  Button,
  IconButton,
  Tooltip,
  Box,
  Snackbar,
  Alert,
  TextField,
  Typography
} from "@mui/material";
import {ContentCopy as CopyIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useData} from "../../hooks/useData";
import {getNormalizedLanguage} from "../../consts/i18n";
import {getEnglishNameByLocale} from "../../consts/languages";

const JsonEditor: React.FC = () => {
  const {t} = useTranslation();
  const [inputJson, setInputJson] = useState<string>('');
  const [formattedJson, setFormattedJson] = useState<string>('');
  const [showBeautified, setShowBeautified] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false); // Track modifications in raw JSON
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [fixExplanation, setFixExplanation] = useState<string | null>(null);

  const {jsonFix} = useData();
  const {mutate, error, isPending, data} = jsonFix;

  useEffect(() => {
    if (data) {
      try {
        const formattedJson = JSON.stringify(data.json, null, 2)
        setFormattedJson(formattedJson);
        setFixExplanation(data.explanation);
        setShowBeautified(true);
        setIsModified(false);
      } catch (error) {
        setFormattedJson(t("pages.json.invalidJson"));
        setShowBeautified(true);
      }
    }
  }, [data, t]);

  useEffect(() => {
    if (error) {
      setFormattedJson(t("pages.json.invalidJson"));
      setShowBeautified(true);
    }
  }, [error, t]);

  useEffect(() => {
    setIsModified(true);
  }, [inputJson])

  const handleJsonFix = () => {
    mutate(
      {
        malformed_json: inputJson,
        language: getEnglishNameByLocale(getNormalizedLanguage())
      }
    );
  };

  const onBeautify = (json: string): string | undefined => {
    try {
      const parsedJson = JSON.parse(json);
      return JSON.stringify(parsedJson, null, 2);
    } catch (error) {
      return undefined;
    }
  };

  const handleBeautify = () => {
    if (isModified) {
      const formatted = onBeautify(inputJson);
      if (formatted === undefined) {
        handleJsonFix();
      } else {
        setIsModified(false);
        setShowBeautified(true);
      }
    } else {
      setShowBeautified(true);
    }

  };

  const handleRawView = () => {
    setShowBeautified(false);
  };

  const handleFormattedView = () => {
    if (!showBeautified) {
      handleBeautify();
    } else {
      setShowBeautified(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(showBeautified ? formattedJson : inputJson).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2} id={'json-editor'}>
      <TextField
        multiline
        fullWidth
        minRows={3}
        maxRows={20}
        value={showBeautified ? formattedJson : inputJson}
        onChange={(e) =>
          showBeautified
            ? setFormattedJson(e.target.value)
            : setInputJson(e.target.value)
        }
        placeholder={t("pages.json.pasteJson")}
        variant="outlined"
        slotProps={{
          input: {
            style: {
              fontFamily: "monospace",
              fontSize: "14px",
            },
          },
        }}
        sx={{
          width: '100%',
          maxWidth: '600px',
        }}
      />
      {fixExplanation && !isModified && (
        <Typography color="textSecondary" variant="body2" align="center">
          {fixExplanation}
        </Typography>
      )}
      <Box display="flex" gap={2}>
        {!showBeautified ? (
          <Button
            onClick={handleFormattedView}
            variant="contained"
            color="primary"
            disabled={isPending}
          >
            {isPending ? t("pages.json.fixing") : t("pages.json.beautify")}
          </Button>
        ) : (
          <Button
            onClick={handleRawView}
            variant="outlined"
            color="secondary"
          >
            {t("pages.json.rawView")}
          </Button>
        )}
        <Tooltip title={t("general.copyToClipboard")}>
          <IconButton onClick={handleCopy} color="default">
            <CopyIcon/>
          </IconButton>
        </Tooltip>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
          {t("general.copiedToClipboard")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JsonEditor;
