import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, {CustomDetector, DetectorOptions} from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

import i18next from "i18next";
import {isLocaleIncluded} from "./languages";

export const getNormalizedLanguage = (): string => {
  return i18next.language.split('-')[0];
};

export const customPathLanguageDetector: CustomDetector = {
  name: 'pathDetector',
  lookup: (options: DetectorOptions): string | undefined => {
    const pathMatch = window.location.pathname.match(/^\/([^/]+)(\/|$)/);
    const lng = pathMatch ? pathMatch[1] : '';
    return isLocaleIncluded(lng) ? lng : undefined;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPathLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      de: { translation: translationDE },
    },
    fallbackLng: 'en',
    detection: {
      order: ['pathDetector', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
