import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import Header from "../components/header";
import Footer from "../components/footer";

interface ErrorType {
  children?: React.ReactNode;
  withPopover?: boolean;
}

const Root = styled.div`
`;

const BaseLayout: React.FC<ErrorType> = ({ children, withPopover = false }) => {
  return (
    <Root>
      <Header withPopover={withPopover}/>
      {children}
      <Outlet/>
      <Footer />
    </Root>
  );
};

export default BaseLayout;
