import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  Typography, IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useData } from "../../hooks/useData";
import {ContentCopy as CopyIcon} from "@mui/icons-material";

const FactSplitter: React.FC = () => {
  const { t } = useTranslation();
  const [inputSentence, setInputSentence] = useState<string>('');
  const [facts, setFacts] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false); // Track modifications
  const { factSplit } = useData();
  const { mutate, data, error, isPending } = factSplit;

  useEffect(() => {
    if (data) {
      setFacts(data.facts || []);
      setIsModified(false);
    }
  }, [data]);

  useEffect(() => {
    setIsModified(true);
  }, [inputSentence]);

  const handleFactSplit = () => {
    mutate({
      sentence: inputSentence,
      language: "German",
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(facts.join('\n')).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
      {/* Input Field */}
      <TextField
        multiline
        fullWidth
        minRows={3}
        maxRows={10}
        value={inputSentence}
        onChange={(e) => setInputSentence(e.target.value)}
        placeholder={t("pages.factSplitting.enterSentence")}
        variant="outlined"
        slotProps={{
          input: {
            style: {
              fontFamily: "monospace",
              fontSize: "14px",
            },
          },
        }}
        sx={{
          width: '100%',
          maxWidth: '600px',
        }}
      />

      {/* Fact Splitting Button */}
      <Button
        onClick={handleFactSplit}
        variant="contained"
        color="primary"
        disabled={isPending || !isModified}
      >
        {isPending
          ? t("pages.factSplitting.extracting")
          : t("pages.factSplitting.splitFacts")}
      </Button>

      {/* Display Facts */}
      {facts.length > 0 && !isModified && (
        <Box
          p={2}
          border="1px solid #ddd"
          borderRadius="4px"
          bgcolor="#f9f9f9"
          width="100%"
          maxWidth="600px"
        >
          <List>
            {facts.map((fact, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${index + 1}. ${fact}`} />
              </ListItem>
            ))}
          </List>
          <IconButton onClick={handleCopy} color="default">
            <CopyIcon/>
          </IconButton>
        </Box>
      )}

      {/* Error Handling */}
      {error && (
        <Typography color="error" variant="body2">
          {t("pages.factSplitting.error")}
        </Typography>
      )}

      {/* Snackbar for Copy Confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t("general.copiedToClipboard")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FactSplitter;
